import React from 'react'; 

import ContactImg from "../../assets/images/contact-img.png";
 
const ContactForm = () => {
    return (
        <div className="contact-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>Get In Touch With Us</h2>
                    <div className="bar"></div>
                    
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <img src={ContactImg} alt="image" />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <form id="contactForm">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            placeholder="Ihr Name" 
                                            className="form-control" 
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="email" 
                                            placeholder="E-Mail-Adresse" 
                                            className="form-control"  
                                        /> 
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="number" 
                                            placeholder="Telefonnummer" 
                                            className="form-control"  
                                        /> 
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="subject" 
                                            placeholder="Betreff" 
                                            className="form-control"  
                                        /> 
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <textarea 
                                            name="text" 
                                            cols="30" 
                                            rows="5" 
                                            placeholder="Nachricht" 
                                            className="form-control"  
                                        /> 
                                    </div>
                                </div>
            
                                <div className="col-lg-12 col-sm-12">
                                    <button type="submit" className="btn btn-primary">Send Message</button>
                                </div>
                            </div>
                        </form> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm;  